var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-contanier" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "tab" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "permissson",
                rawName: "v-permissson",
                value: "ZDSJTJ",
                expression: "'ZDSJTJ'",
              },
            ],
            staticClass: "tabItem",
            class: { active: _vm.active === 0 },
            on: {
              click: function ($event) {
                return _vm.changeType(0, "ZDSJTJ")
              },
            },
          },
          [_vm._v("制单数据统计")]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "permissson",
                rawName: "v-permissson",
                value: "YSTJ",
                expression: "'YSTJ'",
              },
            ],
            staticClass: "tabItem",
            class: { active: _vm.active === 1 },
            on: {
              click: function ($event) {
                return _vm.changeType(1, "YSTJ")
              },
            },
          },
          [_vm._v("营收统计")]
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "data",
      },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
          [
            _c(
              "el-form-item",
              { attrs: { label: !_vm.active ? "上传时间" : "费用日期" } },
              [
                !_vm.active
                  ? _c("el-date-picker", {
                      staticStyle: { width: "320px" },
                      attrs: {
                        type: "daterange",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd",
                        "unlink-panels": "",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.form.upDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "upDate", $$v)
                        },
                        expression: "form.upDate",
                      },
                    })
                  : _c("el-date-picker", {
                      staticStyle: { width: "320px" },
                      attrs: {
                        type: "daterange",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd",
                        "unlink-panels": "",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.form.feeDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "feeDate", $$v)
                        },
                        expression: "form.feeDate",
                      },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.derive,
                    expression: "derive",
                  },
                ],
                staticClass: "derive flex_c_c",
                attrs: { "v-permissson": _vm.derive },
                on: {
                  click: function ($event) {
                    return _vm.deriveStatistics(_vm.active)
                  },
                },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }