<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-permissson="'ZDSJTJ'"
          class="tabItem"
          :class="{ active: active === 0 }"
          @click="changeType(0, 'ZDSJTJ')"
        >制单数据统计</span
        >
        <span
          v-permissson="'YSTJ'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1, 'YSTJ')"
        >营收统计</span
        >
      </div>
    </div>
    <div v-loading="loading" class="data">
      <el-form ref="form" :model="form" label-position="top">
        <el-form-item :label="!active ? '上传时间' : '费用日期'">
          <el-date-picker
            v-if="!active"
            v-model="form.upDate"
            style="width: 320px"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            unlink-panels
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-date-picker
            v-else
            v-model="form.feeDate"
            style="width: 320px"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            unlink-panels
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <div
          v-show="derive"
          :v-permissson="derive"
          class="derive flex_c_c"
          @click="deriveStatistics(active)"
        >导出</div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { permissions, getRecentMonthDate, derive } from '@/utils/common'
export default {
  name: 'OperatingStatement',
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      activeCode: '',
      form: {
        upDate: [],
        feeDate: []
      },
      loading: false
    }
  },
  computed: {
    derive() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '导出')?.code
      return code || ''
    }
  },
  created() {
    this.form.upDate = getRecentMonthDate(1)
    this.form.feeDate = getRecentMonthDate(1)
  },
  mounted() {
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['ZDSJTJ', 'YSTJ']
      const code = menu.children.find((a) => codeList.includes(a.code)).code
      this.active = codeList.indexOf(code)
      this.activeCode = menu.children[0].code
    }
  },
  methods: {
    changeType(type, code) {
      this.active = type
      this.activeCode = code
    },
    async deriveStatistics(type) {
      this.loading = true
      const params = {
        startDate: type ? this.form.feeDate[0] : this.form.upDate[0],
        endDate: type ? this.form.feeDate[1] : this.form.upDate[1]
      }
      await derive(`Analysis/${type ? 'revenueStatistics' : 'orderOnlineStatistics'}`, 'get', params)
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .tab {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .tabItem {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .data {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 52px);
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    padding-top: 80px;
    box-sizing: border-box;
    .derive {
      width: 320px;
      height: 40px;
      background: #2395F3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
